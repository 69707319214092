import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { MenuItem } from '../../layouts/sidebar/menu.model';
import { UtilService } from '../../shared/services/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private utilService: UtilService, private router: Router) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.utilService.getAuthStateWithClaims().pipe(
        switchMap(({ user, claims }) => {
          if (!user) {
            this.router.navigate(['/login']);
            return of(false);
          }
          return user.getIdTokenResult();
        }),
        map((idTokenResult: any) => idTokenResult?.claims.role === 'admin'),
        tap(isAdmin => {
          if (!isAdmin) {
            this.router.navigate(['/']);
          }
        })
    );
  }
}
